import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {ImportStatus} from "@common/import/import-status";

export interface FetchImportStatusResponse extends BackendResponse {
  importStatus: ImportStatus;
}

export function useImportStatus() {
  return useQuery({
    queryKey: [],
    queryFn: () => fetchImportStatus(),
    refetchInterval: 60000
  });
}

function fetchImportStatus(): Promise<FetchImportStatusResponse> {
  return apiClient.get('import/google/get-status').then(response => response.data);
}
