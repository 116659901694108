import {GoogleIcon} from "@common/icons/social/google";
import {Trans} from "@common/i18n/trans";
import {StaticPageTitle} from "@common/seo/static-page-title";
import {Navbar} from "@common/ui/navigation/navbar/navbar";
import {
  SocialLoginPanelRow
} from "@common/auth/ui/account-settings/social-login-panel";
import {NotFoundPage} from "@common/ui/not-found-page/not-found-page";
import {useUser} from "@common/auth/ui/use-user";
import {FullPageLoader} from "@common/ui/progress/full-page-loader";
import React from "react";
import {ImportGoogleFiles} from "@common/import/import-google-files";

export function ImportGooglePage() {
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!data?.user) {
    return <NotFoundPage />;
  }

  const socialUser = data?.user?.social_profiles?.find(s => s.service_name === 'google');

  return (
    <div className="min-h-screen bg-alt">
      <StaticPageTitle>
        <Trans message="Google Drive Import" />
      </StaticPageTitle>
      <Navbar menuPosition="account-settings-page" />
      <div>
        <div className="container mx-auto px-24 py-24">
          <h1 className="text-3xl">
            <Trans message="Google Drive Import" />
          </h1>
          <div className="flex items-start gap-24">

            <SocialLoginPanelRow
              icon={<GoogleIcon viewBox="0 0 48 48" />}
              service="google"
              user={data.user}
              enabled={true}
            />
          </div>

          <div className={"mt-24"}>
            {socialUser && (
              <ImportGoogleFiles user={socialUser} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
